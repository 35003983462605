(<template>
  <article class="download-app">
    <h3 class="download-app__title">{{ $gettext('Salita Android App') }}</h3>
    <div class="download-app__download-link">
      <p>{{ $gettext("Download didn't start automatically?") }}</p>
      <button class="download-app__action"
              @click="downloadFile">{{ $gettext('Retry download') }}</button>
    </div>
    <div v-for="(step, index) in steps"
         :key="index">
      <div class="download-app__image"
           :class="[`download-app__image--${step.name}`]"></div>
      <div class="download-app__step step">
        <div class="step__icon">{{ step.number }}</div>
        <div>
          <h4 class="step__subtitle">{{ step.subtitle }}</h4>
          <p>{{ step.text }}</p>
        </div>
      </div>
    </div>
    <div class="download-app__qr-code step">
      <div class="step__qr-code"></div>
      <div>
        <h4 class="step__subtitle">{{ $gettext('Open on phone') }}</h4>
        <p>{{ $gettext('Scan the QR code to open this page on your phone.') }}</p>
      </div>
    </div>
  </article>
</template>)

<script>
  export default {
    computed: {
      steps() {
        return [
          {
            name: 'access',
            number: 1,
            subtitle: this.$gettext('Allow unknown apps'),
            text: this.$gettext('Search for "Install unknown apps" or "Install other apps" in settings.')
          },
          {
            name: 'install',
            number: 2,
            subtitle: this.$gettext('Install the Salita App'),
            text: this.$gettext('Open the APK file you just downloaded and tap "Install".')
          },
          {
            name: 'unsafe',
            number: 3,
            subtitle: this.$gettext('Unsafe app blocked'),
            text: this.$gettext('If you encounter the "Unsafe app blocked" popup, you need to click on "More details".')
          },
          {
            name: 'unsafe-details',
            number: 3.1,
            subtitle: this.$gettext('Unsafe app blocked'),
            text: this.$gettext('Then click on "Install anyway".')
          }
        ];
      }
    },
    methods: {
      downloadFile() {
        const externalLink = 'https://android.salita.no';
        const link = document.createElement('a');
        link.href = externalLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    mounted() {
      setTimeout(() => {
        this.downloadFile();
      }, 500);
    }
  };
</script>

<style scoped>
.download-app {
  width: 100%;
  max-width: 700px;
  margin: 40px auto;
  padding: 60px;
  border-radius: 8px;
  background-color: #fff;
}

.download-app__title {
  color: #000;
  font-size: 24px;
  text-align: center;
}

.download-app__download-link {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.download-app__action {
  margin-left: 4px;
  color: #ff5b24;
}

.download-app__image {
  margin-top: 60px;
  border-radius: 8px;
}

.download-app__image--access {
  height: 350px;
  background: #f4f5f7 url(~@assets/imgs/salita/download_android_app/download_app_access_image.png) no-repeat center;
}

.download-app__image--install {
  height: 430px;
  background: #f4f5f7 url(~@assets/imgs/salita/download_android_app/download_app_install_image.png) no-repeat center;
}

.download-app__image--unsafe {
  height: 500px;
  background: #f4f5f7 url(~@assets/imgs/salita/download_android_app/download_app_unsafe_image.png) no-repeat center;
}

.download-app__image--unsafe-details {
  height: 530px;
  background: #f4f5f7 url(~@assets/imgs/salita/download_android_app/download_app_unsafe_details_image.png) no-repeat center;
}

.download-app__step {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.step__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  min-width: 52px;
  height: 52px;
  margin-right: 16px;
  border-radius: 50%;
  background-color: #008489;
  color: #fff;
  font-weight: 700;
}

.step__subtitle {
  margin-bottom: 8px;
  font-size: 20px;
}

.download-app__qr-code {
  display: flex;
  align-items: center;
  margin-top: 70px;
  padding: 24px;
  border-radius: 8px;
  background-color: #f4f5f7;
}

.step__qr-code {
  width: 120px;
  height: 120px;
  margin-right: 20px;
  background-image: url(~@assets/imgs/salita/download_android_app/qr_code_android_app.svg);
}

@media (max-width: 767px) {
  .download-app {
    padding: 60px 24px;
  }

  .download-app__download-link {
    flex-direction: column;
    align-items: center;
  }

  .download-app__step {
    display: block;
  }

  .step__icon {
    margin-bottom: 16px;
  }

  .download-app__qr-code {
    display: none;
  }
}
</style>
